<template>
    <div id="wrapper">
        <SidebarView :all-loans="true" :loanVisible="true" :loan="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="mx-3">
                    <div class="card card-shape home-box">
                        <div class="card-header d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 fw-bold text-green">All Loans</h6>
                            <b-link @click="exportToExcel" class="fw-semibold ms-2 akkurate-green"> <font-awesome-icon
                                    :icon="['fas', 'file-excel']" /></b-link>
                        </div>
                        <div class="card-body">
                            <div class="home-box border border-success p-3">
                                <form @submit.prevent="filterTable">
                                    <loan-filter :form='form' :branch_option="branch_option"
                                        :schemes_option="schemes_option" @branch_arr="branch_array"
                                        @schemes_arr="schemes_array"></loan-filter>
                                </form>

                            </div>
                        </div>
                        <div class="card-body pt-0">
                            <div class="table_text_size">
                                <b-table striped bordered responsive="xxl" id="loan-table" :busy.sync="isBusy"
                                    :items="rows" :fields="fields" :tbody-tr-class="rowClass" show-empty>
                                    <template #table-busy>
                                        <div class="text-center text-danger my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong>Loading...</strong>
                                        </div>
                                    </template>
                                    <template #empty="scope">
                                        <h4 class="text-center small mt-3">{{ scope.emptyText }}</h4>
                                    </template>
                                    <template #cell(loan_status)="data">
                                        <span :class="statusBadge(data.item.loan?.status)">{{ data.item.loan?.status
                                            }}</span>
                                    </template>
                                    <!-- A custom formatted column -->
                                    <template #cell(customer)="data">
                                        <div>{{ data.item.customer.first_name }} {{ data.item.customer.last_name
                                            }}
                                        </div>
                                    </template>
                                    <template #cell(actions)="data">
                                        <span class="d-flex">
                                            <a title="View"
                                                class="btn btn-sm text-white akkurate-warning border-0 custom-pointer"
                                                @click="onViewLoan(data.item, 'view')">
                                                <span class="akkurate-small">
                                                    <font-awesome-icon :icon="['fas', 'eye']" />
                                                </span>
                                            </a>
                                            <a title="Edit"
                                                class="btn btn-sm text-white akkurate-blue border-0 custom-pointer"
                                                @click="showLoanEditModal(data.item?.loan)">
                                                <span class="akkurate-small"><font-awesome-icon
                                                        :icon="['fas', 'edit']" /></span>
                                            </a>

                                            <div class="dropdown no-arrow d-inline"
                                                v-if="data.item.loan?.status.toUpperCase() == 'RUNNING' || data.item.loan?.status.toUpperCase() == 'EXPIRED'">
                                                <a title="More Action"
                                                    class="btn btn-sm text-white akkurate-gray-btn custom-pointer border-0 dropdown-toggle"
                                                    id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <span class="akkurate-small"> <font-awesome-icon
                                                            :icon="['fas', 'money-bill-transfer']" />
                                                    </span>
                                                </a>
                                                <div class="dropdown-menu shadow animated--fade-in"
                                                    aria-labelledby="dropdownMenu">
                                                    <div class="dropdown-header">More Actions</div>
                                                    <button class="dropdown-item"
                                                        @click="onViewLoan(data.item, 'repay')">Loan Repayment</button>
                                                    <button class="dropdown-item"
                                                        @click="onViewLoan(data.item, 'topup')">Loan Topup</button>

                                                    <button class="dropdown-item"
                                                        @click="onViewLoan(data.item, 'adjustment')">Loan
                                                        Adjustment</button>

                                                    <button class="dropdown-item"
                                                        @click="onViewLoan(data.item, 'status')">Change Status</button>


                                                    <button class="dropdown-item"
                                                        @click="onViewLoan(data.item, 'refinance')">Refinance</button>

                                                    <button class="dropdown-item"
                                                        @click="onViewLoan(data.item, 'write_off')">Write off</button>

                                                </div>
                                            </div>

                                            <a v-if="data.item.loan?.status.toUpperCase() !== 'RUNNING' && data.item.loan?.status.toUpperCase() !== 'CLOSED'"
                                                title="Delete"
                                                class="btn btn-sm text-white akkurate-danger-btn border-0 custom-pointer"
                                                @click="deleteLoan(data.item)">
                                                <span class="akkurate-small"><font-awesome-icon
                                                        :icon="['fas', 'trash']" /></span>
                                            </a>
                                        </span>
                                    </template>
                                </b-table>
                                <div class="d-flex justify-content-between">
                                    <b-pagination class="mt-1" @change="navigatePage" v-model="currentPage"
                                        :total-rows="totalRecords" :per-page="perPage"
                                        aria-controls="loan-table"></b-pagination>

                                    <div class="col text-end mt-1">
                                        <div>{{ currentPage }} - {{ perPage }} of {{ totalRecords }}</div>
                                        <b-form-group label="Per Page" label-size="sm" class="mb-0">
                                            <b-form-select @change="selectPerPage" id="per-page-select"
                                                v-model="perPage" :options="per_page_options" size="sm"></b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>
                                <!-- Modal: Loan View (Unique for Each Row) -->
                                <b-modal :id="'loan-modal-' + currentLoanId" size="xxl" @shown="showView">
                                    <template #modal-title>
                                        <h6 class="m-0 fw-bold text-green">Loan View</h6>
                                    </template>

                                    <!-- Loan View Component -->
                                    <loan-view :data="currentLoanData" :user="user" :institution="institution"
                                        :coa_subheads="coa_subheads" :term_active_menu="term_active_menu"
                                        :repayment_active_menu="repayment_active_menu"
                                        :statement_active_menu="statement_active_menu"
                                        :entries_active_menu="entries_active_menu"
                                        :adjustment_active_menu="adjustment_active_menu"
                                        :topup_active_menu="topup_active_menu"
                                        :refinance_active_menu="refinance_active_menu"
                                        :writeoff_active_menu="writeoff_active_menu" :user_pemissions="user_pemissions"
                                        :user_role="user_role" :status_active_menu="status_active_menu"
                                        :current_user_id="current_user_id" @loanApprovedAction="loanApprovedAction"
                                        @loanAdjustmenetSaved="loanAdjustmenetSaved" @reloadLoans="reloadLoans"></loan-view>
                                    <template #modal-footer>
                                        <div class="w-100">
                                            <b-button @click="$bvModal.hide('loan-modal-' + currentLoanId)"
                                                type="submit" class="akkurate-gray-btn btn-size float-right">
                                                <font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close
                                            </b-button>
                                        </div>
                                    </template>
                                </b-modal>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <footer></footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>

        <b-modal size="lg" id="bv-modal-loan-edit" hide-footer>
            <template #modal-title>
                <h6 class="m-0 fw-bold text-green text-sm">Loan Edit</h6>
            </template>
            <loan-edit :loan_edit="loan_edit"></loan-edit>
        </b-modal>
    </div>

</template>
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import LoanFilter from '../forms/LoanFilter.vue';
import LoanEdit from '../edit/LoanEdit.vue';
import ApiService from "@/core/services/api.service";
import { decryptData } from '@/crypto';
import LoanView from '../view/LoanView.vue';
import { hasPermission } from '@/helper';
export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        LoanFilter,
        LoanView,
        LoanEdit,
    },
    data() {
        return {
            totalRecords: 0,
            searchFilter: "",
            isLoading: true,
            isSaving: false,
            institution: {},
            statement: "statement",
            entries: "entries",
            currentLoanId: null, // Track the currently viewed loan ID
            currentLoanData: null, // Track the current loan's data to display in the modal
            term_active_menu: true, // Default active tab is "Loan Term"
            repayment_active_menu: false, // Default inactive tab is "Repayments"
            statement_active_menu: false,
            entries_active_menu: false,
            adjustment_active_menu: false,
            topup_active_menu: false,
            refinance_active_menu: false,
            writeoff_active_menu: false,
            status_active_menu: false,
            user_pemissions: "",
            user_role: "",
            current_user_id: "",
            user_role: false,
            loan_edit: {},
            isBusy: true,
            currentPage: 1,
            perPage: 10,
            per_page_options: ['10', '20', '50', '100'],
            per_page_selected: "10",

            form: {
                branch: "",
                filter: "",
                schemes: "",
                search: "",
                loan_number: "",
                branch_ids: "",
                schemes_ids: "",
                status_option: ['PENDING', 'UNDER_REVIEW', 'RUNNING', 'DISBURSED', 'CLOSED', 'REJECTED', 'WRITTEN_OFF'],
                status: ""
            },
            user: [],
            branch_option: [],
            schemes_option: [],
            user_branch: [],
            coa_subheads: {},
            fields: [

                {
                    label: 'Loan #',
                    key: 'loan.loan_number',
                },
                {
                    label: 'Loan_Name',
                    key: 'loan.loan_product.name',
                },
                {
                    label: 'Borrower_Name',
                    key: 'customer',
                },
                {
                    label: 'Principal',
                    key: 'loan.principal_amount',
                },
                {
                    label: 'Principal_Bal',
                    key: 'loan.principal_balance',
                },
                {
                    label: 'Expected_Interest',
                    key: 'loan.expected_interest',
                },
                {
                    label: 'Interest_Bal',
                    key: 'loan.interest_balance',
                },
                {
                    label: 'Total_Bal',
                    key: 'loan.loan_balance',
                },
                {
                    label: 'Start_Date',
                    key: 'loan.interest_start_date',
                },
                {
                    label: 'Status',
                    key: 'loan_status',
                },
                {
                    label: 'Actions',
                    key: 'actions',
                },
            ],
            rows: [],
        }
    },
    mounted() {
        this.getLoanDropdown();
        this.getInsistution();
        this.loadItems();
    },
    methods: {
        rowClass() {
            return 'custom-row-height'
        },
        statusBadge(item) {
            if (item === 'APPROVED') {
                return 'badge akkurate-blue-bg'
            } else if (item === 'REJECTED') {
                return 'badge akkurate-danger-bg'
            } else if (item === 'RUNNING') {
                return 'badge akkurate-green-bg'
            } else if (item === 'PENDING') {
                return 'badge akkurate-warning-bg'
            } else if (item === 'REVERSED') {
                return 'badge akkurate-orange'
            } else if (item === 'EXPIRED') {
                return 'badge akkurate-gray-bg'
            } else {
                return 'badge akkurate-warning-bg'
            }
        },
        async getLoanDropdown() {
            this.$Progress.start();
            this.isLoading = true;
            await ApiService.get("/loans/paginate/dropdown")
                .then((response) => {
                    // console.log(response);
                    this.branch_option = response?.data.branches
                    this.schemes_option = response?.data?.schemes
                    this.user = response?.data?.user
                    this.coa_subheads = response?.data?.coa_subheads
                    this.isLoading = false;
                    this.$Progress.finish();
                }).catch((error) => {
                    // console.log(error);
                })
        },
        navigatePage(index) {
            this.isBusy = true;
            this.currentPage = index;
            this.loadItems()
        },
        selectPerPage(index) {
            this.perPage = index
            this.loadItems()
        },
        filterTable() {
            this.loadItems();
        },
        checkPemission() {
            if (hasPermission('loan_management_interest_only') || hasPermission('loan_management_interest_principal') || hasPermission('loan_management_adjust_term')) {
                return true
            } else {
                return false;
            }

        },

        // load items is what brings back the rows from server
        async loadItems() {
            this.isBusy = true;  // Set isBusy to true when data is being loaded
            this.$Progress.start();
            this.isLoading = true;
            // console.log(this.form.schemes_ids);
            await ApiService.post("/loans/paginate",
                {
                    "page": this.currentPage,
                    "per_page": this.perPage,
                    "scheme_ids": this.form.schemes_ids,
                    "branch_ids": this.form.branch_ids,
                    // "filter": searchFilter,
                    "filter": this.form.filter,
                    "loan_number": this.form?.loan_number,
                    "status": this.form.status,
                    "start_date": this.form.start_date,
                    "end_date": this.form.end_date,
                })
                .then((response) => {
                    // Handle response and update table data
                    this.isBusy = false;  // Set isBusy to false when the data is loaded
                    this.$Progress.finish();  // Finish the progress bar

                    this.totalRecords = response.data.meta.total;
                    this.rows = response.data.data;  // Return data to populate the table

                }).catch((error) => {
                    console.error(error);
                    this.isBusy = false;  // Set isBusy to false in case of an error
                    this.$Progress.finish();  // Finish the progress bar even if there's an error

                    return [];  // Return an empty array in case of error to prevent the table from breaking
                })
                .finally((error) => {
                    // console.log(error);
                });
        },
        branch_array(event) {
            // console.log(event);
            this.form.branch_ids = [];
            this.form.branch_ids = event;
            //    console.log(this.form.branch_ids);
        },
        schemes_array(event) {
            this.form.schemes_ids = [];
            this.form.schemes_ids = event;
            // console.log(this.form.schemes_ids);
        },
        async getInsistution() {
            await ApiService.get('/settings/institution').then((response) => {
                // console.log(response);
                this.institution = response.data.data;
            }).catch((error) => {
                // console.log(error);
            })
        },
        approval() {
            this.loadItems();
        },
        reloadLoans() {
            this.loadItems();
            this.$bvModal.hide('loan-modal-' + this.currentLoanId);
        },
        // Triggered when "View" button is clicked on a row
        onViewLoan(row, type) {
            this.currentLoanId = row.id; // Set the current loan ID
            this.currentLoanData = row; // Set the data of the selected loan to be displayed in the modal
            // Ensure this is done immediately
            // Set the active tab based on the 'type' parameter
            if (type === 'view') {
                this.term_active_menu = true;
                this.repayment_active_menu = false;
                this.statement_active_menu = false;
                this.entries_active_menu = false;
                this.adjustment_active_menu = false;
                this.topup_active_menu = false;
                this.refinance_active_menu = false;
                this.writeoff_active_menu = false;
                this.status_active_menu = false;
            } else if (type === 'repay') {
                this.term_active_menu = false;
                this.repayment_active_menu = true;
                this.statement_active_menu = false;
                this.entries_active_menu = false;
                this.adjustment_active_menu = false;
                this.topup_active_menu = false;
                this.refinance_active_menu = false;
                this.writeoff_active_menu = false;
                this.status_active_menu = false;
            } else if (type === 'entries') {
                this.term_active_menu = false;
                this.repayment_active_menu = false;
                this.entries_active_menu = true;
                this.statement_active_menu = false;
                this.adjustment_active_menu = false;
                this.topup_active_menu = false;
                this.refinance_active_menu = false;
                this.writeoff_active_menu = false;
                this.status_active_menu = false;
            } else if (type === 'statement') {
                this.term_active_menu = false;
                this.repayment_active_menu = false;
                this.entries_active_menu = false;
                this.statement_active_menu = true;
                this.adjustment_active_menu = false;
                this.topup_active_menu = false;
                this.refinance_active_menu = false;
                this.writeoff_active_menu = false;
                this.status_active_menu = false;
            } else if (type === 'adjustment') {
                this.term_active_menu = false;
                this.repayment_active_menu = false;
                this.entries_active_menu = false;
                this.statement_active_menu = false;
                this.adjustment_active_menu = true;
                this.topup_active_menu = false;
                this.refinance_active_menu = false;
                this.writeoff_active_menu = false;
                this.status_active_menu = false;
            } else if (type === 'topup') {
                this.term_active_menu = false;
                this.repayment_active_menu = false;
                this.entries_active_menu = false;
                this.statement_active_menu = false;
                this.adjustment_active_menu = false;
                this.topup_active_menu = true;
                this.refinance_active_menu = false;
                this.writeoff_active_menu = false;
                this.status_active_menu = false;
            } else if (type === 'status') {
                this.term_active_menu = false;
                this.repayment_active_menu = false;
                this.entries_active_menu = false;
                this.statement_active_menu = false;
                this.adjustment_active_menu = false;
                this.topup_active_menu = false;
                this.refinance_active_menu = false;
                this.writeoff_active_menu = false;
                this.status_active_menu = true;
            } else if (type === 'refinance') {
                this.term_active_menu = false;
                this.repayment_active_menu = false;
                this.entries_active_menu = false;
                this.statement_active_menu = false;
                this.adjustment_active_menu = false;
                this.topup_active_menu = false;
                this.refinance_active_menu = true;
                this.writeoff_active_menu = false;
                this.status_active_menu = false;
            } else if (type === 'write_off') {
                this.term_active_menu = false;
                this.repayment_active_menu = false;
                this.entries_active_menu = false;
                this.statement_active_menu = false;
                this.adjustment_active_menu = false;
                this.topup_active_menu = false;
                this.refinance_active_menu = false;
                this.writeoff_active_menu = true;
                this.status_active_menu = false;
            }
            this.$nextTick(() => {
                this.$bvModal.show('loan-modal-' + this.currentLoanId);
            });
        },
        showView() {
            // Optional: Additional logic when the modal is shown
            this.user_pemissions = decryptData(localStorage.getItem("user_pemissions"))
            this.user_role = decryptData(localStorage.getItem("user_role"))
            this.current_user_id = decryptData(localStorage.getItem("userId"))
        },
        loanApprovedAction() {
            this.loadItems();
        },
        loanAdjustmenetSaved() {
            this.loadItems();
            this.$bvModal.hide('loan-modal-' + this.currentLoanId);
        },
        showLoanEditModal(row) {
            console.log(row);
            this.loan_edit = row
            this.$bvModal.show('bv-modal-loan-edit')
        },
        deleteLoan(data) {
            swal.fire({
                title: "Delete Loan",
                text: 'Are you sure you want to delete loan number ' + data.loan.loan_number + ' ?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "YES",
                cancelButtonText: "NO",
                allowOutsideClick: true,
                customClass: {
                    cancelButton: 'btn akkurate-green-gray modal-btn-width text-white me-2', // Custom class for the "NO" button
                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "YES" button
                }
            }).then(result => {
                if (result.value) {

                    this.$Progress.start();
                    ApiService.delete('/loans/' + data.loan.id)
                        .then((response) => {
                            this.$Progress.finish();
                            this.loadItems();
                            // this.$bvToast.toast('Loan Deleted Successfully', {
                            //     title: 'Success',
                            //     variant: 'success',
                            //     solid: true
                            // })
                            swal.fire({
                                // title: response.statusText,
                                text: response.data.message,
                                icon: "success",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                            this.loadItems();
                        }).catch((error) => {
                            this.$Progress.fail();

                            swal.fire({
                                // title: response.statusText,
                                text: error.response.data.message,
                                icon: "error",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                        })
                }
            });

        },
        // exportToExcel() {
        //     const workbook = XLSX.utils.book_new();

        //     // Get the table data
        //     // const tableData = this.$refs.memberTable.filteredRows[0].children;
        //     // console.log(this.$refs.memberTable.filteredRows[0].children)
        //     const tableData = this.$refs.memberTable.filteredRows[0].children.map((index) => {
        //         return {
        //             member_code: index.code,
        //             name: `${index.first_name} ${index.middlename} ${index.last_name}`,
        //             mobile: index.mobile,
        //             address: index.address,
        //             date_of_birth: index.date_of_birth,
        //             registered_date: index.registered_date,
        //             gender: index.gender,
        //             branch: index.branch.name ?? "",
        //         }
        //     });
        //     // console.log(tableData);

        //     // Convert the table data to a worksheet
        //     const worksheet = XLSX.utils.json_to_sheet(tableData);

        //     // Add the worksheet to the workbook
        //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        //     // Generate the Excel file buffer
        //     const excelBuffer = XLSX.write(workbook, {
        //         type: 'array',
        //         bookType: 'xlsx'
        //     });

        //     // Save the Excel file
        //     FileSaver.saveAs(
        //         new Blob([excelBuffer], { type: 'application/octet-stream' }),
        //         'table_data.xlsx'
        //     );
        // },
        exportToExcel() {
            const workbook = XLSX.utils.book_new();

            // Get the table data
            const tableData = this.rows.map((row) => {
                return {
                    loan_number: row.loan.loan_number,
                    product_name: oan.loan_product.name,
                    customer: row.customer,
                    principal_amount: row.loan.principal_amount,
                    principal_balance: row.loan.principal_balance,
                    expected_interest: row.loan.expected_interest,
                    interest_balance: row.loan.interest_balance,
                    loan_balance: row.loan.loan_balance,
                    interest_start_date: row.loan.interest_start_date,
                    loan_status:row.loan_status
                };
            });

            // Convert the table data to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(tableData);

            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Generate the Excel file buffer
            const excelBuffer = XLSX.write(workbook, {
                type: 'array',
                bookType: 'xlsx'
            });

            // Save the Excel file
            FileSaver.saveAs(
                new Blob([excelBuffer], { type: 'application/octet-stream' }),
                'table_data.xlsx'
            );
        }
    }
}

</script>