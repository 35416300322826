<template>
   <div class="container">
      <div class="row">
         <beat-loader-component v-if="isLoading" :isLoading="isLoading"></beat-loader-component>
      </div>
      <div class="row px-3">
         <div class="card card-shape home-box mb-3 border border-success">
            <div class="card-body py-2">
               <form @submit.prevent="getSingleLoan">
                  <div class="row">
                     <div class="col">
                        <b-form-group id="filter" name="filter" label-for="filter">
                           <multiselect id="filter" name="filter" class="mt-2 field-container" aria-describedby="filter"
                              v-model="form_filter.filter" :allow-empty="false" :options="filter_options">
                           </multiselect>
                        </b-form-group>
                     </div>
                     <div class="col">
                        <b-form-group id="filter_value" label-for="filter_value">
                           <b-form-input id="filter_value" name="filter_value" class="mb-2 mt-2 field-container fields"
                              type="text" placeholder="Enter Search Value..." v-model="form_filter.filter_value"
                              aria-describedby="filter_value" required></b-form-input>
                        </b-form-group>
                     </div>
                     <div class="col-3 my-auto">
                        <b-button type="submit" class="akkurate-green-btn akkurate-auth-size w-100"><i
                              class="fas fa-search me-2"></i>Search</b-button>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
      <div class="row px-3">
         <div class="card card-shape home-box p-0">
            <div class="rounded-0 card-header py-0 pe-0 akkurate-skyblue-bg">
               <div class="d-flex align-items-center">
                  <div class="me-3">
                     <h6 class="m-0 fw-bold text-white">ACCOUNT PREVIEW</h6>
                  </div>
                  <div class="me-auto px-2 text-white" v-if="loan.status !== 'RUNNING'">
                     Current Stage: <span class="text-uppercase">{{ loan_stage }}</span>
                  </div>
                  <div class="px-2 text-white me-auto" v-else>
                     Current Status: <span class="text-uppercase">{{ loan.status }}</span>
                  </div>
                  <div class="text-end">
                     <a @click="$bvModal.show('bv-modal-contract')" class="text-white custom-pointer"><font-awesome-icon
                           class="me-2" :icon="['fas', 'receipt']" /></a>
                  </div>
                  <div class="text-end">
                     <a @click="$bvModal.show('bv-modal-loan-form')"
                        class="text-white custom-pointer"><font-awesome-icon class="me-2"
                           :icon="['fas', 'scale-balanced']" /></a>
                  </div>
                  <div class="text-end"
                     v-if="loan.status !== 'RUNNING' && loan.status !== 'DISBURSED' && loan.status !== 'REJECTED'"
                     v-show="getLoanApproval">
                     <b-button class="akkurate-danger-btn btn-size" @click="rejectLoan(loan.loan_number)">
                        <font-awesome-icon class="me-2" :icon="['fas', 'close']" /> Reject
                     </b-button>
                     <b-button v-if="stage_id != '1'" class="akkurate-orange btn-size"
                        @click="reverseLoan(loan.loan_number)">
                        <font-awesome-icon class="me-2" :icon="['fas', 'backward']" /> Reverse
                     </b-button>
                     <b-button class="akkurate-green-btn btn-size" @click="approveLoan(loan.loan_number)">
                        <font-awesome-icon class="me-2" :icon="['fas', 'forward']" /> Approve
                     </b-button>
                  </div>
                  <div class="text-end"
                     v-if="loan.status !== 'RUNNING' && loan.status !== 'DISBURSED' && loan.status !== 'REJECTED'"
                     v-show="getLoanDisbursement">
                     <b-button class="akkurate-danger-btn btn-size" @click="rejectLoan(loan.loan_number)">
                        <font-awesome-icon class="me-2" :icon="['fas', 'close']" /> Reject
                     </b-button>
                     <b-button v-if="stage_id != '1'" class="akkurate-orange btn-size"
                        @click="reverseLoan(loan.loan_number)">
                        <font-awesome-icon class="me-2" :icon="['fas', 'backward']" /> Reverse
                     </b-button>
                     <b-button class="akkurate-green-btn btn-size"
                        v-b-modal="disbursement.id + 'disbursement'"><font-awesome-icon class="me-2"
                           :icon="['fas', 'hand-holding-dollar']" /> Disburse</b-button>
                     <loan-disbursement :loan_account="loan" :institution="institution" :data="disbursement"
                        :disbursement="'disbursement'"></loan-disbursement>
                  </div>
               </div>
            </div>
            <div class="card-body">
               <div class="row">
                  <div class="col text-center">
                     <img :src="loan.account?.customer?.passport_pic_path" style="height:200px" class="img-fluid"
                        alt="user-profile" />
                     <p class="akkurate-green fw-bold akkurate-small mb-0">Profile Image</p>
                  </div>
                  <div class="col text-center">
                     <img :src="loan.account?.customer?.gh_card_front_path" alt="Default Image" style="height:200px"
                        class="img-fluid text-center" />
                     <p class="akkurate-green fw-bold akkurate-small mb-0">ID Card Front</p>
                  </div>
                  <div class="col text-center">
                     <img :src="loan.account?.customer?.gh_card_back_path" alt="Default Image" style="height:200px"
                        class="img-fluid text-center" />
                     <p class="akkurate-green fw-bold akkurate-small mb-0">ID Card Back</p>
                  </div>
                  <div class="col">
                     <p class="mb-0 fw-bold">{{ getFullName }}<span class="ms-3 fw-light">{{
                        loan.account?.customer?.gender
                           }}</span></p>
                     <p class="mb-0">{{ getBranch }}</p>
                     <p class="mb-0"><span class="fw-bold">ADDRESS: </span> {{ loan.account?.customer?.address }}</p>
                     <p class="mb-0"><span class="fw-bold">TEL: </span>{{ loan.account?.customer?.mobile }}</p>
                     <p class="mb-0"><span class="fw-bold">EMAIL: </span>{{ loan.account?.customer?.email }}</p>
                     <p class="mb-0"><span class="fw-bold">REG DATE: </span>{{ new
                        Date(loan.account?.customer?.registered_date).toDateString() }}</p>
                     <p class="mb-0"><span class="fw-bold">STAFF ID: </span>{{ loan.account?.customer?.staff_id }}
                     </p>
                  </div>
               </div>
               <div class="row akkurate-skyblue-bg text-white akkurate-small fw-bold">
                  <div class="col py-1 text-center">
                     Shares: {{ displayNumber(loan.shares_account_balances) }}
                  </div>
                  <div class="col py-1 text-center">
                     Saving: {{ displayNumber(loan.savings_account_balances) }}
                  </div>
                  <div class="col py-1 text-center">
                     Credit: {{ displayNumber(loan.principal_paid + loan.interest_paid) }}
                  </div>
                  <div class="col py-1 text-center">
                     Current Loan {{ displayNumber(loan.principal_amount) }}
                  </div>
                  <div class="col py-1 text-center">
                     Risk Level
                  </div>
                  <div class="col py-1 text-center akkurate-green-bg">
                     Credit Balance: {{ displayNumber(Number(loan.savings_account_balances) -
                        Number(loan.principal_balance)) }}
                  </div>
               </div>
               <!-- Tabs Begins -->
               <div class="row mt-1">
                  <b-tabs content-class="mt-3" justified @activate-tab="activeTab">
                     <b-tab title="Loan Term" :active="term_active_menu" title-link-class="akkurate-small">
                        <Terms :loan="loan" :institution="institution" />
                     </b-tab>
                     <b-tab title="Repayments"
                        :disabled="data.loan.status === 'PENDING' || data.loan.status === 'UNDER_REVIEW'"
                        :active="repayment_active_menu" title-link-class="akkurate-small">
                        <Repayment :loan="loan" :coa_subheads="coa_subheads"
                           :savings_accounts_options="savings_accounts_options" :institution="institution" />
                     </b-tab>
                     <b-tab title="Statements"
                        :disabled="data.loan.status === 'PENDING' || data.loan.status === 'UNDER_REVIEW'"
                        :active="statement_active_menu" title-link-class="akkurate-small">
                        <Statement :institution="this.institution" :loan_number="this.data.loan.loan_number"
                           :loan="this.loan" />
                     </b-tab>
                     <b-tab title="Entries"
                        :disabled="data.loan.status === 'PENDING' || data.loan.status === 'UNDER_REVIEW'"
                        :active="entries_active_menu" title-link-class="akkurate-small">
                        <Entries :institution="institution" :loan_number="this.data.loan.loan_number" :loan="loan" />
                     </b-tab>
                     <!-- <b-tab title="Payments"
                        :disabled="data.loan.status === 'PENDING' || data.loan.status === 'UNDER_REVIEW'"
                        :active="entries_active_menu" title-link-class="akkurate-small">
                        <Entries :loan="loan" />
                     </b-tab> -->
                     <b-tab :disabled="checkPemission()" title="Adjustment" :active="adjustment_active_menu"
                        title-link-class="akkurate-small">
                        <Adjustment :data="data" @loanAdjustmenetSaved="loanAdjustmenetSaved" />
                     </b-tab>
                     <b-tab title="Top up"
                        :disabled="data.loan.status === 'PENDING' || data.loan.status === 'UNDER_REVIEW'"
                        :active="topup_active_menu" title-link-class="akkurate-small">
                        <Topup :loan_number="loan.loan_number" :active_tab_index="active_tab_index"
                           :user_role="user_role" :user_pemissions="user_pemissions" :institution="institution" :current_user_id="current_user_id" />
                     </b-tab>
                     <b-tab title="Refinance"
                        :disabled="data.loan.status === 'PENDING' || data.loan.status === 'UNDER_REVIEW'"
                        :active="refinance_active_menu" title-link-class="akkurate-small">
                        <LoanRefinance />
                     </b-tab>
                     <b-tab :disabled="data.loan.status === 'PENDING' || data.loan.status === 'UNDER_REVIEW'"
                        title="Write off" :active="writeoff_active_menu" title-link-class="akkurate-small">
                        <Writeoff />
                     </b-tab>
                     <b-tab :disabled="data.loan.status === 'PENDING' || data.loan.status === 'UNDER_REVIEW'"
                        title="Status" :active="status_active_menu" title-link-class="akkurate-small">
                        <Status :loan_number="form_filter.filter_value" @reloadLoans="reloadLoans"/>
                     </b-tab>
                     <b-tab title="Collaterals" title-link-class="akkurate-small">
                        <div class="container">
                           <div class="row">
                              <b-table striped hover :busy="isBusy" :items="col_items" :fields="col_fields">
                                 <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                       <b-spinner class="align-middle"></b-spinner>
                                       <strong>Loading...</strong>
                                    </div>
                                 </template>
                                 <template #cell(action)="data">
                                    <div class="d-flex">
                                       <picture-view :data="data.item?.collateral_pictures"></picture-view>
                                       <document-view :data="data.item?.collateral_documents"></document-view>
                                    </div>
                                 </template>
                              </b-table>
                           </div>
                        </div>
                     </b-tab>
                     <b-tab title="Guarantors" title-link-class="akkurate-small">
                        <div class="container">
                           <div class="row">
                              <b-table striped hover :busy="isBusy" :items="gua_items" :fields="gua_fields">
                                 <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                       <b-spinner class="align-middle"></b-spinner>
                                       <strong>Loading...</strong>
                                    </div>
                                 </template>
                                 <template #cell(name)="data">
                                    <p>{{ data.item?.account?.customer?.first_name }} {{
                                       data.item?.account?.customer?.middle
                                       }} {{ data.item?.account?.customer?.last_name }}</p>
                                 </template>
                              </b-table>
                           </div>
                        </div>
                     </b-tab>
                     <b-tab title="Attachments" title-link-class="akkurate-small">
                        <b-card-text>Tab contents 3</b-card-text>
                     </b-tab>
                     <b-tab title="Schedule" title-link-class="akkurate-small">
                        <div class="container">
                           <div class="row trans_details">
                              <b-table striped hover :busy="isBusy" :items="payment_items" :fields="payment_fields">
                                 <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                       <b-spinner class="align-middle"></b-spinner>
                                       <strong>Loading...</strong>
                                    </div>
                                 </template>
                                 <!-- A custom formatted column -->
                                 <template #cell(status)="data">
                                    <!-- <div v-if="data.status == 'Unpaid'">
                                          <b-badge pill variant="danger">{{ data.status }}</b-badge>
                                       </div>
                                       <div v-else>
                                          <b-badge pill variant="success">{{ data.status }}</b-badge>
                                       </div> -->
                                    <!-- <span class="badge badge-pill badge-primary"></span> -->
                                    <span v-if="data.item.status == 'Unpaid'" class="badge rounded-pill bg-danger">{{
                                       data.item.status }}</span>
                                    <span v-else class="badge rounded-pill bg-success">{{ data.item.status }}</span>
                                 </template>
                              </b-table>
                           </div>
                        </div>
                     </b-tab>
                  </b-tabs>
               </div>
            </div>
            <b-modal size="lg" id="bv-modal-contract" hide-footer>
               <template #modal-title>
                  <h6 class="akkurate-green fw-bold mb-0">Loan Contract</h6>
               </template>
               <div class="d-block text-center">
                  <loan-contract :institution="institution" :details="loan"></loan-contract>
               </div>
               <b-button @click="$bvModal.hide('bv-modal-contract')" type="submit"
                  class="akkurate-gray-btn btn-size float-right">
                  <font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close
               </b-button>
            </b-modal>
            <b-modal size="lg" id="bv-modal-loan-form" hide-footer>
               <template #modal-title>
                  <h6 class="akkurate-green fw-bold mb-0">Loan Form</h6>
               </template>
               <div class="d-block text-center">
                  <loan-forms :institution="institution" :loan="loan"></loan-forms>
               </div>
               <b-button @click="$bvModal.hide('bv-modal-contract')" type="submit"
                  class="akkurate-gray-btn btn-size float-right">
                  <font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close
               </b-button>
            </b-modal>
         </div>
      </div>
   </div>

</template>
<script>
import ApiService from "@/core/services/api.service";
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import PictureView from './modal/PictureView.vue'
import DocumentView from './modal/DocumentView.vue'
import LoanForm from "../application/LoanForm.vue";
import LoanFilter from "../statements/form/LoanFilter.vue";
import Multiselect from 'vue-multiselect'
// import html2pdf from "html2pdf.js";
import Adjustment from "./loan-actions/Adjustment.vue";
import Terms from "./loan-actions/Terms.vue"
import Repayment from "./loan-actions/Repayment.vue"
import Statement from "./loan-actions/Statement.vue"
import Entries from "./loan-actions/Entries.vue"
import Topup from "./loan-actions/Topup.vue";
import LoanRefinance from "../application/form/LoanRefinance.vue";
import Writeoff from "./loan-actions/Writeoff.vue";
import Status from "./loan-actions/Status.vue";
import LoanDisbursement from "../disbursement/LoanDisbursement.vue";
import { hasPermission } from "@/helper";
import LoanForms from "./print/LoanForms.vue";
import LoanContract from "./print/LoanContract.vue";
export default {
   props: ['data', 'institution', 'user', 'coa_subheads',
      'term_active_menu',
      'repayment_active_menu',
      'statement_active_menu',
      'entries_active_menu',
      'adjustment_active_menu',
      'topup_active_menu',
      'refinance_active_menu',
      'writeoff_active_menu',
      'user_pemissions',
      'status_active_menu',
      'current_user_id',
      "user_role"
   ],
   components: {
      BeatLoaderComponent,
      PictureView,
      DocumentView,
      LoanForm,
      Multiselect,
      LoanFilter,
      Entries,
      Topup,
      Adjustment,
      Terms,
      Repayment,
      Statement,
      LoanRefinance,
      Writeoff,
      Status,
      LoanDisbursement,
      LoanForms,
      LoanContract
   },
   data() {
      return {
         // ...this.initialState(),
         active_tab_index: "",
         stage_id: "",
         loan: {
            status: "",
            tenor: "",
            interest_rate: "",
            interest_method: "",
            expected_interest: "",
            interest_balance: "",
            interest_paid: "",
            interest_balance: "",
            loan_number: "",
            principal_amount: "",
            principal_paid: "",
            principal_balance: "",
            principal_due: "",
            loan_product: "",
            account: "",
            start_date: "",
            last_payment: "",
            delinquent: "",
            interest_due: "",
            interest_rate: "",
            account: {
               customer: {
                  passport_pic_path: ""
               }
            }
         },
         form_filter: {
            filter: "Loan Code",
            filter_value: this.data.loan?.loan_number,
         },
         filter_options: ['Loan Code'],
         // term_active_menu: false,
         // statement_active_menu: false,
         // repayment_active_menu: false,
         // other data properties

         savings_accounts_options: [],
         isLoading: false,
         isBusy: false,
         full_name: "",
         search: "Search",
         isSearching: false,
         isSaving: false,

         products: [],
         canUpdateStatus: false,
         total: "",
         hybrid: false,
         straight_line: false,
         disbursement: {
            id: 'loan_disbursement'
         },
         loan_stage: 'Awaiting Branch Manager Approval',  // Example, this will be dynamic based on the loan stag
         fields: [
            { key: 'payment_terms', label: 'Payment Terms' },
            { key: 'after_payments', label: 'After Payment' },
         ],
         items: [
            { payment_terms: { title: 'Interest Amount', value: "" }, after_payments: { title: 'Interest Outstanding', value: "" } },
            { payment_terms: { title: 'Principal Amount', value: "" }, after_payments: { title: 'Principal Outstanding', value: "" } },
            { payment_terms: { title: 'Total Repayment', value: "" }, after_payments: { title: 'Total Outstanding', value: "" } },
         ],
         col_fields: [
            {
               key: 'id',
               label: '#',
            },
            {
               key: 'loan_number',
               label: 'Loan Number',
            },
            {
               key: 'product_name',
               label: 'Product Name',
            },
            {
               key: 'status',
               label: 'Status',
            },
            {
               key: 'address',
               label: 'Address',
            },
            {
               key: 'risk_level',
               label: 'Risk Level',
            },
            {
               key: 'action',
               label: 'Action',
            },
         ],
         col_items: [],
         gua_fields: [
            {
               key: 'id',
               label: '#',
            },
            {
               key: 'loan_number',
               label: 'Loan Number',
            },
            {
               key: 'amount',
               label: 'Amount',
            },
            {
               key: 'entry_date',
               label: 'Entry Date',
            },
            {
               key: 'account.account_number',
               label: 'Account Number',
            },
            {
               key: 'name',
               label: 'Guarantor(s) Name',
            },
         ],
         gua_items: [],
         payment_fields: [
            {
               key: 'due_date',
               label: 'Due Date',
            },
            {
               key: 'loan_number',
               label: 'Loan Number',
            },
            {
               key: 'starting_balance',
               label: 'Starting Balance',
            },
            {
               key: 'amount_due',
               label: 'Amount Due',
            },
            {
               key: 'principal_amount',
               label: 'Principal Amount',
            },
            {
               key: 'interest_amount',
               label: 'Interest Amount',
            },
            {
               key: 'ending_balance',
               label: 'Ending Balance',
            },
            {
               key: 'status',
               label: 'Status',
            },
         ],
         payment_items: [],
         repayment_columns: [
            {
               label: "Reference",
               field: "code",
            },
            {
               label: "Loan Number",
               field: "account.loan.loan_number",
            },
            {
               label: "Phone number",
               field: "customer.mobile",
            },
            {
               label: "Full name",
               //field: 'customer.first_name',
               field: this.getFullname,
               title: this.getFullname,
            },
            {
               label: "Amount",
               field: "trans_amount",
            },
            {
               label: "Interest",
               field: "interest",
            },
            {
               label: "Principal",
               field: "principal",
            },
            {
               label: "Ext. Ref",
               field: "external_reference",
            },
            {
               label: "Status",
               field: "trans_status",
            },
            {
               label: "Date",
               //field: 'created_at',
               // field: this.getCreatedAt,
               field: 'trans_date'
            },
            {
               label: "Source",
               field: "source",
            },
            {
               label: "User",
               field: "user",
            },
            {
               label: "Action",
               field: "action",
               html: true,
               //   formatFn: this.renderActions,
            },
         ],
         repayment_items: [],


      }
   },
   computed: {
      getFullName() {
         const first_name = this.loan.account.customer.first_name != undefined ? this.loan.account.customer.first_name : "";
         const middle_name = this.loan.account.customer.middle_name != undefined ? this.loan.account.customer.middle_name : "";
         const last_name = this.loan.account.customer.last_name != undefined ? this.loan.account.customer.last_name : "";
         return first_name + ' ' + middle_name + ' ' + last_name;

      },
      getDuePayment() {
         const payment = Number(this.loan?.interest_due ?? 0) + Number(this.loan?.principal_due ?? 0);
         return payment.toFixed(2);
      },
      getCurrentValue() {
         const current = Number(this.loan?.principal_balance ?? 0) + Number(this.loan?.interest_due ?? 0);
         return current.toFixed(2)
      },
      getLoanBalance() {
         const balance = Number(this.loan?.interest_balance ?? 0) + Number(this.loan?.principal_balance ?? 0);
         return balance.toFixed(2)
      },



      // getTeller() {
      //    let first_name = this.user?.first_name != null ? this.user?.first_name : '';
      //    let middle_name = this.user?.middle_name != null ? this.user?.middle_name : '';
      //    let surname = this.user?.surname != null ? this.user?.surname : '';
      //    return first_name + ' ' + middle_name + ' ' + surname;
      // },
      getBranch() {
         const branch = this.loan?.account?.customer?.branch?.name ? this.loan?.account?.customer?.branch?.name : ""
         return branch
      },
      // getLoanApproval() {
      //    // console.log(this.user_pemissions);
      //    if (this.user_role?.toLowerCase() == 'branch manager') { // Check Branch manager role
      //       if (this.user_pemissions && Array.isArray(this.user_pemissions)) { // Check for user permission
      //          if (this.loan_stage == 'Awaiting Branch Manager Approval') { // Check for Branch Manager Stage
      //             const approval = this.user_pemissions.filter(index => index === 'loan_management_approve');
      //             return approval.length > 0;
      //          }
      //       }
      //       return false; // Return false if user_pemissions is null or undefined
      //    } else if (this.user_role?.toLowerCase() == 'general manager') {
      //       if (this.user_pemissions && Array.isArray(this.user_pemissions)) { // Check for user permission
      //          if (this.loan_stage == 'Awaiting General Manager Approval') { // Check for Branch Manager Stage
      //             const approval = this.user_pemissions.filter(index => index === 'loan_management_approve');
      //             return approval.length > 0;
      //          }
      //       }
      //       return false;
      //    } else if (this.user_role?.toLowerCase() == 'head of credit') {
      //       if (this.user_pemissions && Array.isArray(this.user_pemissions)) { // Check for user permission
      //          if (this.loan_stage == 'Awaiting Head Of Credit Approval') { // Check for Branch Manager Stage
      //             const approval = this.user_pemissions.filter(index => index === 'loan_management_approve');
      //             return approval.length > 0;
      //          }
      //       }
      //       return false;
      //    } else if (this.user_role?.toLowerCase() == 'loan officer') {
      //       if (this.user_pemissions && Array.isArray(this.user_pemissions)) { // Check for user permission
      //          if (this.loan_stage == 'Awaiting Loan Officer Approval') { // Check for Branch Manager Stage
      //             const approval = this.user_pemissions.filter(index => index === 'loan_management_approve');
      //             return approval.length > 0;
      //          }
      //       }
      //       return false;
      //    } else if (this.user_role?.toLowerCase() == 'loan committee chairman') {
      //       if (this.user_pemissions && Array.isArray(this.user_pemissions)) { // Check for user permission
      //          if (this.loan_stage == 'Awaiting Loan Committee Approval') { // Check for Branch Manager Stage
      //             const approval = this.user_pemissions.filter(index => index === 'loan_management_approve');
      //             return approval.length > 0;
      //          }
      //       }
      //       return false;
      //    } else if (this.user_role?.toLowerCase() == 'operations manager') {
      //       if (this.user_pemissions && Array.isArray(this.user_pemissions)) { // Check for user permission
      //          if (this.loan_stage == 'Awaiting Operations Manager Approval') { // Check for Branch Manager Stage
      //             const approval = this.user_pemissions.filter(index => index === 'loan_management_approve');
      //             return approval.length > 0;
      //          }
      //       }
      //       return false;
      //    }
      // },

      getLoanApproval() {
         if (this.user_role && this.user_pemissions && Array.isArray(this.user_pemissions)) {
            const role = this.user_role?.toLowerCase(); // Convert role to lowercase
            const stage = this.loan_stage?.toLowerCase(); // Convert loan stage to lowercase

            if (role === 'branch manager' && stage === 'awaiting branch manager approval') {
               return this.user_pemissions.includes('loan_management_approve');
            } else if (role === 'general manager' && stage === 'awaiting general manager approval') {
               return this.user_pemissions.includes('loan_management_approve');
            } else if (role === 'head of credit' && stage === 'awaiting head of credit approval') {
               return this.user_pemissions.includes('loan_management_approve');
            } else if (role === 'loan officer' && stage === 'awaiting loan officer approval') {
               return this.user_pemissions.includes('loan_management_approve');
            } else if (role === 'loan committee chairman' && stage === 'awaiting loan committee approval') {
               return this.user_pemissions.includes('loan_management_approve');
            } else if (role === 'operations manager' && stage === 'awaiting operations manager approval') {
               return this.user_pemissions.includes('loan_management_approve');
            }
         }
         return false;
      },

     


      // getLoanDisbursement() {
      //    if (this.user_role?.toLowerCase() === 'head Of credit') { // Check Branch manager role
      //       if (this.user_pemissions && Array.isArray(this.user_pemissions)) { // Check for user permission
      //          if (this.loan_stage == 'Awaiting Head Of Credit Disbursement') { // Check for Branch Manager Stage
      //             const approval = this.user_pemissions.filter(index => index === 'loan_management_disburse');
      //             return approval.length > 0;
      //          }
      //       }
      //       return false; // Return false if user_pemissions is null or undefined
      //    } else if (this.user_role?.toLowerCase() === 'accountant') {
      //       // console.log(this.loan_stage);
      //       if (this.user_pemissions && Array.isArray(this.user_pemissions)) { // Check for user permission
      //          if (this.loan_stage == 'Awaiting Disbursement' || this.loan_stage == 'Awaiting Accountant Disbursement') { // Check for Branch Manager Stage
      //             console.log(this.loan_stage);
      //             const approval = this.user_pemissions.filter(index => index === 'loan_management_disburse');
      //             return approval.length > 0;
      //          }
      //       }
      //    }
      // }

      getLoanDisbursement() {
         if (this.user_role && this.user_pemissions && Array.isArray(this.user_pemissions)) {
            const role = this.user_role?.toLowerCase(); // Convert role to lowercase
            const stage = this.loan_stage?.toLowerCase(); // Convert loan stage to lowercase

            // Check for 'Head of Credit' role and the 'Awaiting Head Of Credit Disbursement' stage
            if (role === 'head of credit' && stage === 'awaiting head of credit disbursement') {
               const approval = this.user_pemissions.filter(index => index === 'loan_management_disburse');
               return approval.length > 0;

               // Check for 'Accountant' role and the 'Awaiting Disbursement' or 'Awaiting Accountant Disbursement' stages
            } else if (role === 'accountant' && (stage === 'awaiting disbursement' || stage === 'awaiting accountant disbursement')) {
               console.log(this.loan_stage); // Optional log for debugging
               const approval = this.user_pemissions.filter(index => index === 'loan_management_disburse');
               return approval.length > 0;
            }
         }

         return false; // Default return false if conditions are not met
      }

   },
   mounted() {
      if (this.data.loan.loan_number != null) {
         this.getSingleLoan()
      }
   },
   methods: {
      activeTab(event) {
         // console.log(event)
         this.active_tab_index = event;
      },
      loanAdjustmenetSaved() {
         this.$emit('loanAdjustmenetSaved')
      },
      checkPemission() {
         if (hasPermission('loan_management_interest_only') || hasPermission('loan_management_interest_principal') || hasPermission('loan_management_adjust_term')) {
            return false;
         } else {
            return true;
         }
      },
      reloadLoans(){
         this.$emit('reloadLoans')
      },
      // validateState(ref) {
      //    if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
      //       return !this.veeErrors.has(ref);
      //    }
      //    return null;
      // },
      // handleOk(bvModalEvent) {
      //    // Prevent modal from closingisDisabled
      //    bvModalEvent.preventDefault();
      //    // Trigger submit handler
      //    this.handleSubmit();
      // },

      // handleDenomination(denominations) {
      //    this.form.denominations = denominations
      // },
      handleAmountInput() {
         const interest_due = this.getInterestDue;
         // console.log(last_payment_date);
         // console.log(this.loan.delinquent);
         // console.log(this.loan.principal_balance);
         // const interest_due = this.getInterestDue;
         // console.log(interest_due);

         var interest_amt = this.form.amount;
         var total = this.form.amount;
         var principal_amt = 0;

         if (this.form.amount > interest_due) {
            principal_amt = this.form.amount - interest_due;
            interest_amt = this.form.amount - principal_amt;
            total = principal_amt + interest_due;
         }

         this.items[0].payment_terms.value = Math.round(interest_amt * 100) / 100;
         this.items[1].payment_terms.value = Math.round(principal_amt * 100) / 100;
         this.items[2].payment_terms.value = Math.round(total * 100) / 100;
         // const outstanding = this.loan.principal_balance - this.form.amount;

         var interest_outstanding = this.loan.interest_balance - Math.round(interest_amt * 100) / 100;;

         this.items[0].after_payments.value = interest_outstanding;
         this.items[1].after_payments.value = this.loan.interest_balance - interest_outstanding;
         this.items[2].after_payments.value = this.loan.interest_balance;
      },
      async getSingleLoan() {
         this.isLoading = true;
         this.isBusy = true,
            await ApiService.get(`/loans/${this.form_filter.filter_value}`)
               .then((response) => {
                  this.isLoading = false;
                  this.isBusy = false,
                     this.loan = response.data.data;
                  // console.log(this.loan);
                  this.col_items = response.data.data.collaterals;
                  this.gua_items = response.data.data.guarantors;
                  this.payment_items = response.data.data.payment_schedules;
                  // this.filterLoanStatement();
                  this.savings_accounts_options = response.data.data.savings_accounts;
                  this.loan_stage = this.loan.loan_approval_level?.name
                  console.log(this.loan.loan_approval_level?.stage)
                  this.stage_id = this.loan.loan_approval_level?.stage
                  // console.log(this.loan);
                  // if (response.data?.data?.interest_method == 'straight-line') {
                  //    this.straight_line = false;
                  //    this.hybrid = true;
                  //    // console.log("Straight Line");
                  // } else if (response.data?.data?.interest_method == 'reducing-balance') {
                  //    this.straight_line = true;
                  //    this.hybrid = true;
                  // } else if (response.data?.data?.interest_method == 'hybrid') {
                  //    this.straight_line = true;
                  //    this.hybrid = false;
                  // }
                  let the_status = response?.data?.data?.status;
                  if (the_status == "RUNNING" || the_status == "EXPIRED" || the_status == "CLOSED" || the_status == "REJECTED" || the_status == "DISBURSED") {
                     this.canUpdateStatus = false
                  } else {
                     this.canUpdateStatus = true
                  }
                  // this.loadTransactions();
               }).catch((error) => {
                  this.search = "Search";
                  this.isSearching = false;
                  // console.log(error);
                  if (error.response?.status == 404) {
                     swal.fire({
                        icon: "error",
                        title: error.response.statusText,
                        text: "Something went wrong!",
                        showCancelButton: false, // Show the "Cancel" button
                        confirmButtonText: 'OK', // Customize the text for the "OK" button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                           confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        }
                        // footer: '<a href="#">Why do I have this issue?</a>'
                     });
                  }
               });
      },

      async approveLoan(filter = null) {
         this.isSaving = true;
         this.$Progress.start();
         swal.fire({
            title: "Approve Loan",
            text: 'Are you sure you want to approve loan number ' + filter + ' ?',
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "YES",
            cancelButtonText: "NO",
            allowOutsideClick: true,
            customClass: {
               cancelButton: 'btn akkurate-green-gray modal-btn-width text-white me-2', // Custom class for the "NO" button
               confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "YES" button
            }

         }).then(result => {
            if (result.value) {
               this.$Progress.start();
               ApiService.post('/loans/approveLoan', { 'loan_number': filter })
                  .then((response) => {
                     this.$Progress.finish();
                     swal.fire({
                        // title: response.statusText,
                        text: response.data.message,
                        icon: "success",
                        allowOutsideClick: true,
                        allowEscapeKey: true,
                        showCancelButton: false, // Show the "Cancel" button
                        confirmButtonText: 'okay', // Customize the text for the "OK" button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                           confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        }
                     });
                     this.getSingleLoan();
                     this.$emit('loanApprovedAction')
                  }).catch((error) => {
                     this.$Progress.fail();
                     swal.fire({
                        // title: response.statusText,
                        text: error.response?.data?.message,
                        icon: "error",
                        allowOutsideClick: true,
                        allowEscapeKey: true,
                        showCancelButton: false, // Show the "Cancel" button
                        confirmButtonText: 'okay', // Customize the text for the "OK" button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                           confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        }
                     });
                  })
            }
         });
      },

      rejectLoan(filter = null) {
         this.isSaving = true;
         this.$Progress.start();
         swal.fire({
            title: "Reject Loan",
            text: 'Are you sure you want to reject loan number ' + filter + ' ?',
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "YES",
            cancelButtonText: "NO",
            allowOutsideClick: true,
            customClass: {
               cancelButton: 'btn akkurate-green-gray modal-btn-width text-white me-2', // Custom class for the "NO" button
               confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "YES" button
            }

         }).then(result => {
            if (result.value) {
               this.$Progress.start();
               ApiService.post('/loans/rejectLoan', { 'loan_number': filter })
                  .then((response) => {
                     this.$Progress.finish();
                     swal.fire({
                        // title: response.statusText,
                        text: response.data.message,
                        icon: "success",
                        allowOutsideClick: true,
                        allowEscapeKey: true,
                        showCancelButton: false, // Show the "Cancel" button
                        confirmButtonText: 'okay', // Customize the text for the "OK" button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                           confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        }
                     });
                     this.getSingleLoan();
                     this.$emit('loanApprovedAction')
                  }).catch((error) => {
                     this.$Progress.fail();
                     swal.fire({
                        // title: response.statusText,
                        text: error.response?.data?.message,
                        icon: "error",
                        allowOutsideClick: true,
                        allowEscapeKey: true,
                        showCancelButton: false, // Show the "Cancel" button
                        confirmButtonText: 'okay', // Customize the text for the "OK" button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                           confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        }
                     });
                  })
            }
         });
      },

      reverseLoan(filter = null) {
         this.isSaving = true;
         this.$Progress.start();
         swal.fire({
            title: "Reverse Loan",
            text: 'Are you sure you want to reverse loan number ' + filter + ' ?',
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "YES",
            cancelButtonText: "NO",
            allowOutsideClick: true,
            customClass: {
               cancelButton: 'btn akkurate-green-gray modal-btn-width text-white me-2', // Custom class for the "NO" button
               confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "YES" button
            }

         }).then(result => {
            if (result.value) {
               this.$Progress.start();
               ApiService.post('/loans/reverseLoan', { 'loan_number': filter })
                  .then((response) => {
                     this.$Progress.finish();
                     swal.fire({
                        // title: response.statusText,
                        text: response.data.message,
                        icon: "success",
                        allowOutsideClick: true,
                        allowEscapeKey: true,
                        showCancelButton: false, // Show the "Cancel" button
                        confirmButtonText: 'okay', // Customize the text for the "OK" button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                           confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        }
                     });
                     this.getSingleLoan();
                     this.$emit('loanApprovedAction')
                  }).catch((error) => {
                     this.$Progress.fail();
                     swal.fire({
                        // title: response.statusText,
                        text: error.response?.data?.message,
                        icon: "error",
                        allowOutsideClick: true,
                        allowEscapeKey: true,
                        showCancelButton: false, // Show the "Cancel" button
                        confirmButtonText: 'okay', // Customize the text for the "OK" button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                           confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        }
                     });
                  })
            }
         });

      },
      clearForm() {
         this.form = this.initialState().form; // Call the initialState method to get the default form
      },


      // showView() {
      //    this.getSingleLoan();
      //    this.user_role = localStorage.getItem("user_role")
      // }
   },
}
</script>
